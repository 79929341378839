import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { width, minWidth, space as styledSpace } from 'styled-system'
import { Text } from 'components/Typography'
import { space, colors, fontSizes, radius, COLOR_CONSTANTS } from 'theme'
import { pxToRem } from 'helpers'
import { Box, Flex } from './Layout'

const StyledInput = styled.input`
  padding: ${space.s};
  height: ${({ isSmall }) => (isSmall ? pxToRem(30) : pxToRem(40))};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${({ borderRadius }) => borderRadius};
  color: ${colors.primaryText};
  font-size: ${fontSizes.s};
  ${({ hasBoxShadow }) => hasBoxShadow && `box-shadow: 0px 20px 40px rgba(39, 40, 49, 0.1);`}
  :focus {
    outline-style: none;
  }
  ::placeholder {
    color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
    font-size: ${fontSizes.s};
  }
  ${width}
  ${minWidth}
  ${styledSpace}
`

const Input = ({ iconComp, error, iconPosition, hasBoxShadow, borderRadius, ...props }) => (
  <Flex flexDirection="column">
    <Flex alignItems="center" position="relative">
      <StyledInput hasBoxShadow={hasBoxShadow} borderRadius={borderRadius} {...props} />
      {iconComp && (
        <Box position="absolute" {...iconPosition}>
          {iconComp}
        </Box>
      )}
    </Flex>
    <Text textAlign="left" mt="s" color="primary">
      {error}
    </Text>
  </Flex>
)

Input.defaultProps = {
  iconPosition: { right: '10px' },
  hasBoxShadow: false,
  borderRadius: radius.s,
}

Input.propTypes = {
  iconComp: PropTypes.node,
  error: PropTypes.string,
  iconPosition: PropTypes.object,
  label: PropTypes.string,
  hasBoxShadow: PropTypes.bool,
  borderRadius: PropTypes.string,
}

export default Input
