import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { radius, COLOR_CONSTANTS } from 'theme'
import { Text } from 'components/Typography'
import { Box, Flex } from 'components/Layout'
import plus from 'static/svg/plus.svg'
import minus from 'static/svg/minus.svg'

const StyledItem = styled(Flex)`
  background: ${COLOR_CONSTANTS.WHITE};
  border: 1px solid #f1f3f5;
  border-radius: ${radius.m};
`
const TitleWrapper = styled(Flex)`
  cursor: pointer;
`
const DescriptionWrapper = styled(Text)`
  white-space: pre-line;
`
const Item = ({ id, title, description, selectedId }) => {
  const [isOpen, setIsOpen] = useState(id === selectedId)
  const itemRef = useRef(null)

  useEffect(() => {
    if (id === selectedId) {
      window.scrollTo(0, itemRef.current.offsetTop - 85)
    }
  }, [])

  const togglePanel = () => {
    setIsOpen(!isOpen)
  }

  return (
    <StyledItem px="l" width="100%" flexDirection="column" ref={itemRef}>
      <TitleWrapper py="m" onClick={togglePanel} alignItems="center">
        <Box>{isOpen ? <img src={plus} alt="" /> : <img src={minus} alt="" />}</Box>
        <Text ml="m" fontWeight="bold" fontSize="l" textAlign="left">
          {title}
        </Text>
      </TitleWrapper>
      {isOpen && (
        <DescriptionWrapper
          mt="m"
          fontSize="m"
          textAlign="left"
          pb="m"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </StyledItem>
  )
}
Item.defaultProps = {
  selectedId: '',
}
Item.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selectedId: PropTypes.string,
}

export default Item
