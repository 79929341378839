/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { radius, COLOR_CONSTANTS } from 'theme'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, H4, Text } from 'components/Typography'
import Container from 'components/Container'
import LocalImage from 'components/LocalImage'
import Input from 'components/Input'
import Footer from 'components/Footer'
import search from 'static/svg/search.svg'
import Item from './Item'

const TabWrapper = styled(Text)`
  color: ${({ active }) => (active ? COLOR_CONSTANTS.FLUORESCENT_RED : COLOR_CONSTANTS.SALUTE)};
  border-bottom: ${({ active }) => (active ? `2px solid ${COLOR_CONSTANTS.FLUORESCENT_RED}` : 'none')};
  cursor: pointer;
`

const StyledImage = styled(LocalImage)`
  cursor: pointer;
`

const TAB = 'all'

const Faq = ({ pageContext: { title, description, pagePath, faq: FREQUENTLY_ASKED_QUESTIONS, openQuestionId } }) => {
  //const Faq = ({ pageContext }) => {

  const [tab, setTab] = useState(TAB)
  const [faq, setFaq] = useState(FREQUENTLY_ASKED_QUESTIONS[tab])
  const [searchValue, setSearchValue] = useState('')

  const handleSubmitSearch = (e) => {
    e.preventDefault()
    if (searchValue) {
      setFaq(
        FREQUENTLY_ASKED_QUESTIONS[tab].filter(
          ({ title }) => title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
        )
      )
    } else {
      setFaq([...FREQUENTLY_ASKED_QUESTIONS[tab]])
    }
  }

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value)
    if (!e.target.value) {
      setFaq([...FREQUENTLY_ASKED_QUESTIONS[tab]])
    } else {
      handleSubmitSearch(e)
    }
  }

  // const handleChangeTab = (tab) => {
  //   setTab(tab)
  //   setFaq(FREQUENTLY_ASKED_QUESTIONS[tab])
  // }

  return (
    <App>
      <SEO title={title} description={description} path={pagePath} />
      <Container maxWidth="1080px">
        {/*
        <Flex height={25} mt="xl" mb="m" justifyContent="center">

          <TabWrapper mx="m" fontSizes="m" active={tab === 'all'} onClick={() => handleChangeTab('business')}>
            General
          </TabWrapper>

          <TabWrapper mx="m" fontSizes="m" active={tab === 'business'} onClick={() => handleChangeTab('business')}>
            Business
          </TabWrapper>
          <TabWrapper mx="m" fontSizes="m" active={tab === 'agency'} onClick={() => handleChangeTab('agency')}>
            Agency
          </TabWrapper>
        </Flex>
        */}

        <Flex mt="m" flexDirection="column" alignItems="center" textAlign="center">
          <H1>Help Center</H1>
          <H4 mt="m" color="secondaryText" opacity={0.8}>
            We want to make sure you're getting the most you can out of Agency Vista
          </H4>

          <Box my="l">
            <form onSubmit={handleSubmitSearch}>
              <Input
                onChange={handleSearchChange}
                width="300px"
                placeholder="Start typing your question"
                pl="40px"
                iconPosition={{ left: '10px' }}
                iconComp={<StyledImage src={search} onClick={handleSubmitSearch} />}
                hasBoxShadow
                borderRadius={radius.m}
              />
            </form>
          </Box>

          <Grid mt="m" py="m" px="s" width={['100%']} gridGap="m">
            {faq.map(({ title, description, id }, key) => (
              <Item
                key={key.toString() + tab}
                id={id}
                title={title}
                description={description}
                selectedId={openQuestionId}
              />
            ))}
          </Grid>
        </Flex>
      </Container>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

Faq.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    pagePath: PropTypes.string.isRequired,
    faq: PropTypes.object,
    openQuestionId: PropTypes.string,
  }),
}

export default Faq
